import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../../contexts/auth'
import { ModalContext } from '../../contexts/modal'
import { validateEmail } from '../../utils'

const SignUp = () => {
  const { auth, authMethods, authStatus } = useContext(AuthContext)
  const { signUpIsFetching, signUpIsSuccess } = authStatus
  const [ isEmpty, setIsEmpty ] = useState(true)
  const [ displayName, setDisplayName ] = useState('')
  const [ email, setEmail ] = useState('')
  const { signUpMessage } = auth

  useEffect(()=>{
    if (!displayName || !email || !validateEmail(email)) {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
    }
  },[displayName, email])

  const signUp = () => {
    const form = {
      displayName,
      email,
    }
    authMethods.signUp(form)
  }

  if (signUpIsSuccess) {
    return (
      <div style={{color: '#000'}}>
        <p className="is-size-2">Sign-up successful!</p>
        <p className="is-size-5">Check your email to activate.</p>
      </div>
    )
  }

  return (
    <>
      <div className="has-text-black is-size-4">
        Sign-up
      </div>
      <div className="has-text-black">
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          style={{
            visibility: (signUpIsFetching) ? 'visible' : 'hidden'
          }}
        />
      </div>
      <div className="field">
        <label className="label is-size-5" htmlFor="display-name">Display Name</label>
        <div className="control">
          <input
            type="text"
            className="input"
            id="display-name"
            onChange={({target})=>{
              setDisplayName(target.value)
            }}
            disabled={signUpIsFetching}
          />
        </div>
      </div>
      <div className="field">
        <label className="label is-size-5" htmlFor="email">Email</label>
        <div className="control">
          <input
            type="text"
            className="input"
            id="email"
            onChange={({target})=>{
              setEmail(target.value)
            }}
            disabled={signUpIsFetching}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="is-size-5 has-text-black">{ signUpMessage }</div>
        </div>
        <div className="column is-narrow">
          <div className="field">
            <div className="control is-pulled-right">
              <button
                type="button"
                className="button is-primary is-size-4 p-3"
                onClick={signUp}
                disabled={isEmpty || signUpIsFetching}
              >Sign-up</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUp
