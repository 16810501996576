import React from 'react'
import { useLocation } from 'react-router-dom'
import BlogList from '../../components/blog-list'
import BlogPost from '../../components/blog-post'

const Blog = () => {
  let { pathname } = useLocation()
  pathname = pathname.endsWith('/')
    ? pathname.substr(0, pathname.length - 1) : pathname;
  const split = pathname.split('/')
  const last = split[split.length-1]
  const component = (last === 'blog') ? <BlogList /> : <BlogPost route={last} />
  return component
}

export default Blog
