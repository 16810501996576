import React, { useContext, useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { v4 } from 'uuid'
import { BlogContext } from '../../contexts/blog'
import { AuthContext } from '../../contexts/auth'
import { ModalContext } from '../../contexts/modal'
import './index.scss'

const Comment = ({ comments, comment, i })=>{
  const { auth } = useContext(AuthContext)
  const { blog, blogMethods, blogStatus } = useContext(BlogContext)
  const { popLogIn } = useContext(ModalContext).modalMethods
  const { createComment, setReplyTo, upvote, downvote, setUpdatingFor, updateComment, deleteComment } = blogMethods
  const { replyTo, blogPost, updatingFor } = blog
  const { isRequestingComments } = blogStatus
  const { me } = auth
  const isLoggedIn = !!me.id

  const keyDown = async (type, e) => {
    const func = type === 'reply' ? setReplyTo : setUpdatingFor
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault()
      if (!e.target.value) return
      if (type === 'reply') {
        await createComment(
          me.id,
          blogPost.id,
          e.target.value,
        )
        e.target.value = ''
      } else {
        await updateComment(
          comment.id,
          blogPost.id,
          me.id,
          e.target.value,
        )
      }
      e.target.removeEventListener('keydown', keyDown)
      func(null)
    } else if (e.keyCode === 27) {
      func(null)
    }
  }

  const lineIsHidden = (i===comments.length-1) ? 'is-hidden' : ''
  const showReplyBox = replyTo === comment.id ? '' : 'is-hidden'
  const showUpdateBox = updatingFor === comment.id ? '' : 'is-hidden'
  const showCommentBody = updatingFor !== comment.id ? '' : 'is-hidden'
  const replyBox = useRef()
  const updateBox = useRef()
  const hasUpvote = comment.has_upvote ? 'voted' : ''
  const hasDownvote = comment.has_downvote ? 'voted' : ''
  const getAbbr = (name) => name?.split(" ")?.map(name=>name[0]?.toUpperCase())?.toString()?.replace(',','')?.substr(0,2)

  useEffect(()=>{
    if (replyTo === comment.id) replyBox.current.focus()         
    if (updatingFor === comment.id) {
      const { length } = updateBox.current.value
      updateBox.current.focus() 
      updateBox.current.setSelectionRange(length, length);
    }
  },[replyTo, updatingFor])

  return (
    <>
      <div className="columns is-vcentered mb-0 mt-1 is-desktop is-mobile">
        <div className="column is-narrow pb-0 pl-0 pt-0 pr-2">
          <span className="avatar">{getAbbr(comment.user.display_name)}</span>
        </div>
        <div className="column pl-0 pb-0 pt-0">
          <div className="name">{comment.user.display_name}</div>
        </div>
      </div>
      <div className="columns is-desktop is-mobile is-multiline mb-0" style={{position: 'relative'}}>
        <div className="column is-full comment-body pb-0">
          <div
            style={{ whiteSpace: 'pre-wrap' }}
            className={`${showCommentBody} pb-1`}
          >{comment.body}</div>
          <div>
            {comment.is_my_comment ? (
              <textarea
                className={`update-comment ${showUpdateBox}`}
                disabled={isRequestingComments}
                onFocus={(e)=>{
                  e.target.addEventListener('keydown', keyDown.bind(null, 'edit'))
                }}
                onBlur={(e)=>{
                  e.target.removeEventListener('keydown', keyDown.bind(null, 'edit'))
                  setUpdatingFor(null)
                }}
                ref={updateBox}
                defaultValue={comment.body}
              />
            ) : null}
          </div>
          <div>
            <span
              className={`comment-control has-pointer ${hasUpvote}`}
              onClick={()=>{
                if (me.id)
                  upvote(me.id, comment.id, blogPost.id, comment.has_upvote)
                else
                  popLogIn()
              }}
            >
              <FontAwesomeIcon icon={faArrowUp} className="mr-1" />
              {comment.upvotes}
            </span>
            <span
              className={`comment-control has-pointer ${hasDownvote}`}
              onClick={()=>{
                if (me.id)
                  downvote(me.id, comment.id, blogPost.id, comment.has_downvote)
                else
                  popLogIn()
              }}
            >
              <FontAwesomeIcon icon={faArrowDown} className="mr-1" />
              {comment.downvotes}
            </span>
            {comment.is_my_comment ? (
              <span
                className="comment-control has-pointer"
                onClick={()=>{
                  setUpdatingFor(comment.id)
                }}
              >
                Edit
              </span>
            ):(
              <span
                className="comment-control has-pointer"
                onClick={()=>{
                  if (me.id)
                    setReplyTo(comment.id)
                  else
                    popLogIn()
                }}
              >
                Reply
              </span>
            )}
            {comment.is_my_comment ? (
              <span
                className="comment-control has-pointer"
                onClick={()=>{
                  const shouldRemove = confirm('Are you sure?')
                  if (shouldRemove) {
                    deleteComment(comment.id, me.id, blogPost.id)
                  }
                }}
              >
                Delete
              </span>             
            ):null}
          </div>
          <div>
            <textarea
              className={`reply-comment ${showReplyBox}`}
              disabled={isRequestingComments}
              onFocus={(e)=>{
                e.target.addEventListener('keydown', keyDown.bind(null, 'reply'))
              }}
              onBlur={(e)=>{
                e.target.removeEventListener('keydown', keyDown.bind(null, 'reply'))
                setReplyTo(null)
              }}
              ref={replyBox}
            />
          </div>
          <CommentTree comments={comment.replys} />
        </div>
        <div className={`line-down ${lineIsHidden}`} />
      </div>
    </>
  )
}

const CommentTree = ({ comments }) => {
  return (
    <>
      {comments.map((comment, i)=>(
        <Comment
          comments={comments}
          comment={comment}
          i={i}
          key={`comment-${v4()}`}
        />
      ))}
    </>
  )
}

export default CommentTree
