import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { ModalContext } from '../../contexts/modal'
import { AuthContext } from '../../contexts/auth'
import ResetPassword from '../../components/reset-password'

const ResetPasswordView = () => {
  const {
    open,
    close,
    setContent,
    setxIsHidden,
  } = useContext(ModalContext).modalMethods
  const { authMethods, authStatus, auth } = useContext(AuthContext)
  const { resetPassword, canResetPassword, resetAuthModals } = authMethods
  const { resetPasswordIsPossible, resetPasswordIsFetching } = authStatus
  const { resetPasswordMessage, me } = auth
  const history = useHistory()
  const params = new URLSearchParams(useLocation().search)
  const id = params.get('i')
  const hash = params.get('h')
  const url = params.get('u') || '/'

  const onSubmit = async (password) => {
    resetPassword(id, hash, password)
  }

  useEffect(()=>{
    setxIsHidden(true)
    open()
    setContent(
      <FontAwesomeIcon icon={faSpinner} spin />
    )
    canResetPassword(id, hash)
  },[])

  useEffect(()=>{
    if (!resetPasswordIsPossible && resetPasswordMessage) {
      setContent(
        <>
          <div className="is-size-5 has-text-black">
            {resetPasswordMessage}
          </div>
        </>
      )
    } else if(resetPasswordIsPossible) {
      setContent(
        <ResetPassword onSubmit={onSubmit} />
      )
    }
  },[resetPasswordIsPossible, resetPasswordMessage])

  useEffect(()=>{
    if (me.id) {
      close()
      resetAuthModals()
    }
  },[me.id])

  return null
}

export default ResetPasswordView
