import React, { createContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { io } from 'socket.io-client'

export const SocketContext = createContext()

const addr = `${process.env.RAZZLE_API_DOMAIN || 'http://localhost:5000'}/bill-godfrey`

const SocketProvider = props => {
  const [ socket, setSocket ] = useState(null)
  const { pathname } = useLocation()

  useEffect(()=>{
    setSocket(io(addr))
  },[])

  useEffect(()=>{
    if (socket) {
      socket.emit('pathname-changed', pathname)
    }
  }, [socket, pathname])
  
  return (
    <SocketContext.Provider
      value={{ socket }}
    >
      {props.children}
    </SocketContext.Provider>
  )
}

export default SocketProvider
