import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../../contexts/auth'
import { ModalContext } from '../../contexts/modal'
import { validateEmail } from '../../utils'

const LoginForm = () => {
  const { auth, authMethods, authStatus } = useContext(AuthContext)
  const { modalMethods } = useContext(ModalContext)
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ isEmpty, setIsEmpty ] = useState(true)
  const { loginIsFetching } = authStatus
  const { loginMessage, me } = auth

  const submit = () => {
    authMethods.login(email, password)
    setPassword('')
  }

  useEffect(()=>{
    if (!email || !password || !validateEmail(email)) {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
    }
  },[email, password])

  useEffect(()=>{
    if (me.id) {
      modalMethods.close()
    }
  },[me.id])

  return (
    <form onSubmit={(e)=>{
      e.preventDefault()
      if (!isEmpty) submit()
    }}>
      <div className="has-text-black is-size-4">
        Log-in
      </div>
      <div className="has-text-black">
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          style={{
            visibility: (loginIsFetching) ? 'visible' : 'hidden'
          }}
        />
      </div>
      <div className="field">
        <label className="label is-size-5">Email</label>
        <div className="control">
          <input
            className="input"
            type="text"
            value={email}
            onChange={({target})=>{
              setEmail(target.value)
            }}
          />
        </div>
      </div>

      <div className="field">
        <label className="label is-size-5">Password</label>
        <div className="control">
          <input
            className="input"
            type="password"
            value={password}
            onChange={({target})=>{
              setPassword(target.value)
            }}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="is-size-5 has-text-black">
            { loginMessage }
          </div>
        </div>
        <div className="column is-narrow" style={{alignSelf: 'flex-end'}}>
          <div className="control is-pulled-right">
            <button
              className="button is-primary is-size-4 p-3"
              disabled={isEmpty}
            >Log-in</button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default LoginForm
