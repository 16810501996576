import React, { useContext, useEffect } from 'react'
import { BlogContext } from '../../contexts/blog'
import { AuthContext } from '../../contexts/auth'
import { SocketContext } from '../../contexts/socket'
import CommentTree from '../comment-tree'
import CommentBox from '../comment-box'
import './index.scss'

const CommentSection = (noPadding) => {
  const { blog, blogStatus, blogMethods } = useContext(BlogContext) 
  const { auth, authStatus } = useContext(AuthContext)
  const { socket } = useContext(SocketContext)
  const { me } = auth
  const { profileIsFetching } = authStatus
  const { blogPost, comments, commentsNeedReload } = blog
  const { 
    isRequestingComments,
    isErrorComments,
  } = blogStatus
  const {
    getComments,
    reloadComments,
  } = blogMethods

  useEffect(()=>{
    if (socket) {
      socket.off('update-comments')
      socket.on('update-comments', ({ blogId, userId }) =>{
        if (
          blogPost.id === blogId
          && (
            !me.id
            || me.id !== userId
          )
        ) {
          reloadComments()
        }
      })
    }
    return ()=>{
      if (socket) {
        socket.off('update-comments')
      }
    }
  }, [socket, blogPost.id, me.id])

  useEffect(()=>{
    if (
      profileIsFetching
      && Array.isArray(comments)
      && !isRequestingComments
      && !isErrorComments
    ) {
      reloadComments(true)
    }
    if (
      (
        blogPost.title
        && !Array.isArray(comments)
        && !isRequestingComments
        && !isErrorComments
        && !profileIsFetching
      ) || (
        commentsNeedReload
        && !isRequestingComments
        && !profileIsFetching
      )
    ) {
      let userId = me.id ? me.id : null
      getComments(blogPost.id, userId)
    }
  },[blogPost.title, comments, isRequestingComments, me.id, profileIsFetching, commentsNeedReload])

  const tree = Array.isArray(comments) ? <CommentTree comments={comments} /> : null

  return (
    <div className="comments-container">
      <h3 className="my-3">Comments</h3>
      <CommentBox />
      {tree}
    </div>
  )
}

export default CommentSection
