import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom'
import { HeadProvider } from 'react-head'
import BlogProvider from './contexts/blog'
import AuthProvider from './contexts/auth'
import ModalProvider from './contexts/modal'
import SocketProvider from './contexts/socket'
import ResponsiveProvider from './contexts/responsive'
import Nav from './components/nav'
import CallToAction from './components/call-to-action'
import ElectronicPressKit from './components/electronic-press-kit'
import Blog from './views/blog'
import Activate from './components/activate'
import Modal from './components/modal'
import Head from './components/head'
import ResetPasswordView from './views/reset-password'
import 'animate.css'
import './bulma.sass'
import './App.css'

function App({ headTags }) {
  const { pathname } = useLocation()

  useEffect(()=>{
    const backdrop = document.getElementById('backdrop')
    if (pathname.includes('blog')) {
      backdrop.classList.add('blur')
    } else {
      backdrop.classList.remove('blur')
    }
  },[pathname])

  return (
    <HeadProvider headTags={headTags}>
      <Head />
      <ResponsiveProvider>
        <SocketProvider>
          <ModalProvider>
            <AuthProvider>
              <BlogProvider>
                <div className="App">
                  <Switch>
                    <Route
                      exact
                      path="/"
                      component={CallToAction}
                    />
                    <Route
                      exact
                      path="/epk"
                      component={ElectronicPressKit}
                    />
                    <Route
                      path="/blog"
                      component={Blog}
                    />
                    <Route
                      exact
                      path="/activate"
                      component={Activate}
                    />
                    <Route
                      exact
                      path="/reset"
                      component={ResetPasswordView}
                    />
                  </Switch>
                  <Nav />
                  <Modal />
                </div>
              </BlogProvider>
            </AuthProvider>
          </ModalProvider>
        </SocketProvider>
      </ResponsiveProvider>
    </HeadProvider>
  );
}

export default App;
