import React, { useContext, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../contexts/auth'
import { ModalContext } from '../../contexts/modal'
import { BlogContext } from '../../contexts/blog'
import './index.scss'

const CommentBox = () => {
  const { auth, authMethods, authStatus } = useContext(AuthContext)
  const { popLogIn, popSignUp } = useContext(ModalContext).modalMethods
  const { blog, blogMethods, blogStatus } = useContext(BlogContext)
  const [ value, setValue ] = useState('')
  const { blogPost } = blog
  const { createComment, setReplyTo } = blogMethods
  const { isRequestingComments } = blogStatus
  const { me } = auth
  const isLoggedIn = !!me.id
  const displayOverlay = isLoggedIn ? 'is-hidden' : ''
  const displayButton = isLoggedIn ? '' : 'is-hidden'
  const textArea = useRef()

  return (
    <div className="input-container">
      <textarea
        className="input main-comment"
        disabled={!isLoggedIn || isRequestingComments}
        ref={textArea}
        onFocus={(e)=>{
          setReplyTo(null)
        }}
        onChange={(e)=>{
          setValue(e.target.value)
        }}
        value={value}
      />
      <div className={`has-text-right mt-1 mb-4`}>
        <button
          type="button"
          className={`button is-warning ${displayButton}`}
          onClick={async ()=>{
            await createComment(
              me.id,
              blogPost.id,
              value
            )
            setValue('')
          }}
          disabled={!value || isRequestingComments}
        >
          Send
        </button>
      </div>
      <div className={`comment-overlay ${displayOverlay}`}>
        <span className="link-immitation" onClick={popLogIn}>Log-in</span>
        <span style={{margin: "0 10px"}}>or</span>
        <span className="link-immitation" onClick={popSignUp}>Sign-up</span>
      </div>
    </div>
  )
}

export default CommentBox
