import React, { useState, useEffect } from 'react'
import { Title, Meta } from 'react-head'
import Cookies from 'browser-cookies'
import { useLocation } from 'react-router-dom'
import Service from '../../service'
import SEO from '../../../seo.json'

const DEFAULT = {
  title: 'Bill Godfrey - Songwriter, Technologist, Space Cadet',
  description: 'The website of Bill Godfrey, a songwriter and technologist based in Austin, Texas, featuring his musical releases, interactive audio-visual art projects, electronic press kit, social media, and blog',
  image: `${Service.CDN}/bg_logo_blue_gJFt_2pKf.png?w=300`,
  og_type: 'website',
}

const Head = () => {
  const { pathname } = useLocation()
  const meta = SEO.find(s => s.route === pathname) || DEFAULT

  useEffect(()=>{
    const shouldBeAnalytics = process.env.NODE_ENV === 'production' && !Cookies.get('bgna')
    if (shouldBeAnalytics) {
      window.gtag('event', 'page_view', { 'send_to': 'G-NJWGT24J4G' })
    }
  },[pathname])

  return (
    <>
      <Title>{meta.title}</Title>
      <Meta property="og:title" name="og:title" content={meta.title} />
      <Meta property="description" name="description" content={meta.description} />
      <Meta property="og:description" name="og:description" content={meta.description} />
      <Meta property="og:image" name="og:image" content={`${Service.CDN}${meta.image}`} />
      <Meta property="og:type" name="og:type" content={meta.og_type} />
    </>
  )
}

export default Head
