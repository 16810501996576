import React, { useContext, useEffect } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { BlogContext } from '../../contexts/blog'
import Service from '../../service'
import CommentSection from '../comment-section'
import './index.scss'

const BlogPost = ({ route }) => {
  const { blog, blogStatus, blogMethods } = useContext(BlogContext)
  const { blogPost } = blog
  const {
    isRequestingBlog,
    isErrorBlog,
  } = blogStatus

  useEffect(()=>{
    if (blogPost.route !== route && !isRequestingBlog && !isErrorBlog) {
      blogMethods.getOneBlog(route)
    }
  },[blogPost, isRequestingBlog, route])

  const published_at = (blogPost.published_at) ? moment(blogPost.published_at).format('M/D/YYYY') : null
  const isBlog = !!published_at

  if (isRequestingBlog) return 'Loading...'

  return (
    <div className="page blog-post animate__animated animate__fadeIn animate__slow" >
      <div className="go-back">
        <Link to="/blog">
          <FontAwesomeIcon icon={faArrowLeft} className="mr-4" /> 
          Back
        </Link>
      </div>
      {isBlog ? (
        <>
          <div className="full-post-container">
            <h1>
              {published_at} {blogPost.title}
            </h1>
            <p>
              <img src={`${Service.CDN}${blogPost.image}`} alt={blogPost.alt} className="blog-image" />
              <span dangerouslySetInnerHTML={{ __html: blogPost.body}} />
            </p>
          </div>
          <div className="full-post-container">
            <CommentSection />
          </div>
        </>
      ):null}
    </div>
  )
}

export default BlogPost
