import Cookies from 'browser-cookies'

const DOMAIN = (process.env.RAZZLE_API_DOMAIN) ? `${process.env.RAZZLE_API_DOMAIN}/v1` : null || 'http://localhost:5000/v1'
const CDN = process.env.RAZZLE_CDN
const _getUri = route => `${DOMAIN}${route}`

const uri_login = _getUri('/auth/login')
const uri_blogList = _getUri(`/blog/list`)
const uri_blogPost = _getUri(`/blog/post?route=:route`)
const uri_comment_tree = _getUri('/comments/tree?id=:blogid')
const uri_comment = _getUri('/comments')
const uri_activate = _getUri(`/auth/activate`)
const uri_canActivate = _getUri(`/auth/can-activate`)
const uri_requestResetPassword = _getUri(`/auth/request-reset`)
const uri_canResetPassword = _getUri(`/auth/can-reset`)
const uri_finishResetPassword = _getUri(`/auth/finish-reset`)
const uri_profile = _getUri('/auth/profile')
const uri_signUp = _getUri('/auth/sign-up')
const uri_requestActivation = _getUri('/users/request-activation')
const uri_upvote = _getUri('/upvotes')
const uri_downvote = _getUri('/downvotes')

const serviceMethods = {
  getAllBlogs: async () => {
    const res = await fetch(uri_blogList)
    return res
  },
  getOneBlog: async (route) => {
    const uri = uri_blogPost.replace(/:route/, route)
    const res = await fetch(uri)
    return res
  },
  getComments: async (id, user_id=null) => {
    let uri = uri_comment_tree.replace(/:blogid/, id)
    if (user_id) uri += `&user_id=${user_id}`
    const res = await fetch(uri)
    return res
  },
  createComment: async (user_id, blog_id, body, replying_to, token) => {
    const res = await _superFetch(
      uri_comment,
      token,
      'POST',
      {
        user_id,
        blog_id,
        body,
        replying_to,
      }
    ) 
    return res
  },
  updateComment: async (comment_id, blog_id, user_id, body, token) => {
    const res = await _superFetch(
      `${uri_comment}/${comment_id}`,
      token,
      'PUT',
      {
        user_id,
        blog_id,
        body,
      }
    ) 
    return res
  },
  deleteComment: async (comment_id, user_id, blog_id, token) => {
     const res = await _superFetch(
      `${uri_comment}/${comment_id}`,
      token,
      'DELETE',
      {
        user_id,
        blog_id,
      }
    ) 
    return res   
  },
  upvote: async (user_id, comment_id, blog_id, remove=false, token) => {
    const method = remove ? 'DELETE' : 'POST'
    const res = await _superFetch(
      uri_upvote,
      token,
      method,
      {
        user_id,
        comment_id,
        blog_id,
      }
    )
    return res
  },
  downvote: async (user_id, comment_id, blog_id, remove=false, token) => {
    const method = remove ? 'DELETE' : 'POST'
    const res = await _superFetch(
      uri_downvote,
      token,
      method,
      {
        user_id,
        comment_id,
        blog_id,
      }
    )
    return res
  },
  canActivateUser: async (id, hash) =>{
    const res = await fetch (uri_canActivate, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        hash,
      })
    })
    return res
  },
  activateUser: async (id, hash, password) => {
    const res = await fetch(uri_activate, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({
        id,
        hash,
        password,
      }),
    })
    return res
  },
  requestResetPassword: async (email, url) => {
    const res = await fetch(uri_requestResetPassword, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        url,
      }),
    })
    return res
  },
  canResetPassword: async (id, hash) => {
    const res = await fetch(uri_canResetPassword, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        hash
      }),
    })
    return res
  },
  resetPassword: async(id, hash, password) => {
    const res = await fetch(uri_finishResetPassword, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        hash,
        password,
      }),
    })
    return res
  },
  getProfile: async (token) => {
    const res = await _superFetch(uri_profile, token)
    return res
  },
  login: async (email, password) => {
    const creds = {
      email,
      password,
    }
    const res = await fetch(
      uri_login,
      {
        method: 'POST',
        body: JSON.stringify(creds),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return res
  },
  signUp: async (displayName, email, pathname) => {
    const res = await fetch (
      uri_signUp,
      {
        method: "POST",
        body: JSON.stringify({
          display_name: displayName,
          email,
          url: pathname,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return res
  },
  requestActivation: async (identifier, pathname) => {
    const res = await fetch(
      uri_requestActivation,
      {
        method: "POST",
        body: JSON.stringify({
          identifier,
          url: pathname,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return res
  },
}

const _superFetch = async (uri, token, method='GET', body={}) => {
  const params = {
    headers: _getAuthHeaders(token),
    method,
  }
  if (typeof body === 'object' && Object.keys(body).length > 0) {
    params.body = JSON.stringify(body)
  }
  const res = await fetch(uri,params)
  return res
}

export default {
  ...serviceMethods,
  CDN,
}

const _getAuthHeaders = (token) => ({
  'Authorization': `Bearer ${token}`,
  'Content-Type': 'application/json',
})
