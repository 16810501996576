import React, { createContext, useState } from 'react'
import LoginForm from '../components/login-form'
import SignUp from '../components/sign-up'
import ResetPasswordRequest from '../components/reset-password-request'

export const ModalContext = createContext()

const ModalProvider = props => {
  const [ isActive, setIsActive ] = useState(false)
  const [ content, setContent ] = useState(null)
  const [ width, setWidth ] = useState(null)
  const [ xIsHidden, setxIsHidden ] = useState(false)

  const modal = {
    isActive,
    content,
    width,
    xIsHidden,
  }

  const modalMethods = {
    open: () => {
      setIsActive(true)
    },
    close: () => {
      setWidth(null)
      setContent(null)
      setIsActive(false)
    },
    setContent,
    setxIsHidden,
    setModalWidth: newWidth => {
      setWidth(newWidth)
    },
    popLogIn: () => {
      setxIsHidden(false)
      setContent(<LoginForm />)
      setIsActive(true)
    },
    popSignUp: () => {
      setxIsHidden(false)
      setContent(<SignUp />)
      setIsActive(true)
    },
    popResetPasswordRequest: () => {
      setxIsHidden(false)
      setContent(<ResetPasswordRequest />)
      setIsActive(true)
    },
  }

  return (
    <ModalContext.Provider
      value={{ modal, modalMethods }}
    >
      {props.children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
