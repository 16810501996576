import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../../contexts/auth'
import './index.scss'

const MIN_LENGTH = 6

const ResetPassword = ({onSubmit}) => {
  const [ password1, setPassword1 ] = useState('')
  const [ password2, setPassword2 ] = useState('')
  const [ isDisabled, setIsDisabled ] = useState(false)
  const { formUsername, activationMessage, resetPasswordMessage } = useContext(AuthContext).auth
  const { pathname } = useLocation()
  const text = pathname.includes('activate') ? 'Set Password' : 'Reset Password'
  const message = pathname.includes('activate') ? activationMessage : resetPasswordMessage

  useEffect(()=>{
    if (
      !password1
      || !password2
      || password1 !== password2
      || password1.length < 6
    ) setIsDisabled(true)
    else setIsDisabled(false)
  },[password1, password2])

  return (
    <div>
      <div className="has-text-black">
        <h1>{text}</h1>
        <p>Must be at least 6 characters long.</p>
      </div>
      <form className="form" onSubmit={e=>{
        e.preventDefault()
        onSubmit(password1)
      }}>
        <input
          type="text"
          name="username"
          id="username"
          value={formUsername}
          className="invisible-username"
        />
        <div className="field">
          <label className="label is-size-5" htmlFor="password1">
            New Password
          </label>
          <div className="control">
            <input
              type="password"
              className="input"
              name="password"
              id="password1"
              value={password1}
              onChange={({target})=>{
                setPassword1(target.value)
              }}
            />
          </div>
        </div>
        <div className="field">
          <label className="label is-size-5" htmlFor="password2">
            Re-type Password
          </label>
          <div className="control">
            <input
              type="password"
              className="input"
              id="password2"
              value={password2}
              onChange={({target})=>{
                setPassword2(target.value)
              }}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column has-text-black">
            { message }
          </div>
          <div className="column is-narrow">
            <div className="field">
              <div className="control is-pulled-right">
                <button className="button is-primary is-size-4 p-3" disabled={isDisabled}>
                  { text }
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ResetPassword
