import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Cookies from 'browser-cookies'
import Service from '../service'
import { ModalContext } from './modal'

export const AuthContext = createContext()

const DEFAULT = {
  token: null,
  me: {
    id: null,
    displayName: null,
    email: null,
  },
}

const productionHosts = ['localhost:3000', 'billgodfrey.com']

const COOKIE_HOST = process.env.RAZZLE_COOKIE_HOST || 'localhost'

const AuthProvider = ({children}) => {
  const { popLogIn, popSignUp, popResetPasswordRequest } = useContext(ModalContext).modalMethods
  const [ token, setToken ] = useState(DEFAULT.token)
  const [ me, setMe ] = useState(DEFAULT.me)
  const { pathname, search, host } = useLocation()
  const history = useHistory()
  const [ loginIsFetching, setLoginIsFetching ] = useState(false)
  const [ profileIsFetching, setProfileIsFetching ] = useState(false)
  const [ resetPasswordIsFetching, setResetPasswordIsFetching ] = useState(false)
  const [ resetPasswordRequestIsSuccess, setResetPasswordRequestIsSuccess ] = useState(false)
  const [ resetPasswordIsPossible, setResetPasswordIsPossible ] = useState(true)
  const [ resetPasswordMessage, setResetPasswordMessage ] = useState('')
  const [ signUpIsFetching, setSignUpIsFetching ] = useState(false)
  const [ signUpIsSuccess, setSignUpIsSuccess ] = useState(false)
  const [ activationIsFetching, setActivationIsFetching ] = useState(false)
  const [ activationIsPossible, setActivationIsPossible ] = useState(true)
  const [ activationMessage, setActivationMessage ] = useState('')
  const [ formUsername, setFormUsername ] = useState('')
  const COOKIE_NAME = productionHosts.includes(host) ? 'at' : 'ats'

  const defaultLoginMessage = (
    <>
      <p>
        <span
          className="has-text-info has-pointer"
          onClick={()=>{
            popResetPasswordRequest()
          }}
        >Forgot your password?</span>
      </p> 
      <p>or</p>
      <p>
        <span
          className="has-text-info has-pointer"
          onClick={()=>{
            popSignUp()
          }}
        >Need to sign-up?</span>
      </p>
    </>
  )

  const [ loginMessage, setLoginMessage ] = useState(defaultLoginMessage)

  const defaultSignUpMessage = (
    <>
      <p>
        <span
          className="has-text-info has-pointer"
          onClick={()=>{
            popLogIn()
          }}
        >Already registered?</span>
      </p>
    </>
  )
  const [ signUpMessage, setSignUpMessage ] = useState(defaultSignUpMessage)

  const authStatus = {
    loginIsFetching,
    profileIsFetching,
    signUpIsFetching,
    signUpIsSuccess,
    resetPasswordIsFetching,
    resetPasswordRequestIsSuccess,
    resetPasswordIsPossible,
    activationIsFetching,
    activationIsPossible,
  }

  const successfulLogin = (data) => {
    const { token, user } = data
    Cookies.set(COOKIE_NAME, token, { expires: 2, domain: COOKIE_HOST })
    setToken(token)
    setMe(user)
  }

  const failedLogin = (message, messageFunc=setLoginMessage) => {
    if (message === 'account-locked') {
      messageFunc(
        <>
          <p>Too many failed log-in attempts.</p>
          <p>Account locked for 10 minutes.</p>
          <p>
            Would you like to
            {" "}
            <span
              className="has-text-info has-pointer ml-2"
              onClick={()=>{
                setLoginMessage(defaultLoginMessage)
                popResetPasswordRequest()
              }}
            >reset your password</span>?
          </p>
        </>
      )
    } else if (message === 'user-not-found') {
      messageFunc(
        <>
          <p>This email has not yet been registered.</p>
          <p>
            Would you like to
            {" "}
            <span
              className="has-text-info has-pointer ml-2"
              onClick={()=>{
                setLoginMessage(defaultLoginMessage)
                popSignUp()
              }}
            >sign-up</span>?
          </p>
        </>
      )
    } else if (message === 'user-not-activated') {
      messageFunc(
        <>
          <p>This account has not yet been activated.</p>
          <p>Please click the link in your email to activate.</p>
          <p>
            Would you like to
            <span
              className="has-text-info has-pointer ml-2"
              onClick={()=>{
                authMethods.requestActivation(email)
              }}
            >re-send the email</span>?
          </p>
        </>
      )
    } else if (message === 'bad-password') {
      messageFunc(
        <>
          <p>You have entered the wrong password.</p>
          <p>
            Would you like to
            {" "}
            <span
              className="has-text-info has-pointer ml-2"
              onClick={()=>{
                setLoginMessage(defaultLoginMessage)
                popResetPasswordRequest()
              }}
            >reset your password</span>?
          </p>
        </>
      )
    } else {
      messageFunc(
        <>
          <p>Something went wrong.</p>
          <p>Please try again later.</p>
        </>
      )
    }
  }

  const failedActivation = (id, message) => {
    if (message === 'user-not-found') {
      setActivationMessage(
        <>
          <p>This is an invalid activation link.</p>
          <p>
            Would you like to
            <span
              className="has-text-info has-pointer mx-2"
              onClick={()=>{
                setActivationMessage('')
                popLogIn()
              }}
            >log-in</span>?
          </p>
        </>
      )
    } else if (message === 'activation-expired') {
      setActivationMessage(
        <>
          <p>This activation link has expired.</p>
          <p>
            Would you like to
            <span
              className="has-text-info has-pointer ml-2"
              onClick={()=>{
                authMethods.requestActivation(id, setActivationMessage)
              }}
            >get another one</span>?
          </p>
        </>
      )
    } else if (message === 'account-already-activated') {
      setActivationMessage(
        <>
          <p>This account is already activated.</p>
          <p>
            Would you like to
            <span
              className="has-text-info has-pointer mx-2"
              onClick={()=>{
                setActivationMessage('')
                popLogIn()
              }}
            >log-in</span>?
          </p>
        </>
      )
    } else {
      setActivationMessage(
        <>
          <p>Something went wrong.</p>
          <p>Please try again later.</p>
        </>
      )
    }
  }

  const failedResetPassword = (message) => {
    if (message === 'reset-not-found') {
      setResetPasswordMessage(
        <>
          <p>This is an invalid reset password link.</p>
          <p>
            Would you like to
            {" "}
            <span
              className="has-text-info has-pointer ml-2"
              onClick={()=>{
                setResetPasswordMessage('')
                popResetPasswordRequest()
              }}
            >reset your password</span>?
          </p>
        </>
      )
    } else if (
      message === 'reset-already-completed'
      || message === 'reset-expired'
    ) {
      setResetPasswordMessage(
        <>
          <p>This reset link is expired.</p>
          <p>
            Would you like to
            {" "}
            <span
              className="has-text-info has-pointer ml-2"
              onClick={()=>{
                setResetPasswordMessage('')
                popResetPasswordRequest()
              }}
            >reset your password</span>?
          </p>
        </>
      )
    } else {
      setResetPasswordMessage(
        <>
          <p>Something went wrong.</p>
          <p>Please try again later.</p>
        </>
      )
    }
  } 

  const authMethods = {
    login: async (email, password) => {
      setLoginIsFetching(true)
      const res = await Service.login(email, password)
      const { data, message } = await res.json()
      setLoginIsFetching(false)
      if (res.ok) {
        successfulLogin(data)
      } else {
        failedLogin(message)
      }
    },
    signUp: async ({ displayName, email }) => {
      setSignUpIsFetching(true)
      const res = await Service.signUp(displayName, email, pathname)
      const { message } = await res.json()
      setSignUpIsFetching(false)
      if (res.ok) {
        setSignUpIsSuccess(true)
        setSignUpMessage(defaultSignUpMessage)
      } else {
        setSignUpIsSuccess(false)
        if (message === 'account-already-exists') {
          setSignUpMessage(
            <>
              <p>This email already exists in our records.</p>
              <p>
                Try
                <span
                  className="has-text-info has-pointer mx-2"
                  onClick={()=>{
                    setSignUpMessage(defaultSignUpMessage)
                    popLogIn()
                  }}
                >log-in</span>
                or
                <span
                  className="has-text-info has-pointer ml-2"
                  onClick={()=>{
                    setSignUpMessage(defaultSignUpMessage)
                    popResetPasswordRequest()
                  }}
                >reset password</span>.
              </p>
            </>
          )
        } else {
          setSignUpMessage(
            <>
              <p>Something went wrong.</p>
              <p>Please try again later.</p>
            </>
          )
        }
      } 
    },
    logout: async () => {
      setToken(DEFAULT.token)
      setMe(DEFAULT.me)
      Cookies.erase(COOKIE_NAME, { domain: COOKIE_HOST })
    },
    getProfile: async () => {
      setProfileIsFetching(true)
      const res = await Service.getProfile(token)
      if (res.ok) {
        const { data } = await res.json()
        setMe(data.user)
      } else {
        Cookies.erase(COOKIE_NAME, { domain: COOKIE_HOST })
      }
      setProfileIsFetching(false)
    },
    resetAuthModals: () => {
      setSignUpIsSuccess(false)
      setSignUpMessage(defaultSignUpMessage)
      setResetPasswordRequestIsSuccess(false)
      setResetPasswordMessage('')
      setLoginMessage(defaultLoginMessage)
      if (
        pathname.includes('activate')
        || pathname.includes('reset')
      ) {
        const params = new URLSearchParams(search)
        const url = params.get('u') || '/'
        history.push(url)
      }
    },
    requestActivation: async (identifier, messageFunc=setLoginMessage) => {
      const res = await Service.requestActivation(identifier, pathname)
      if (res.ok) {
        messageFunc(
          <>
            <p>The activation email was re-sent.</p>
            <p>If the email is not in your inbox, be sure to check your spam folder.</p>
          </>
        )
      } else {
        messageFunc(
          <>
            <p>Something went wrong.</p>
            <p>Please try again later.</p>
          </>
        )
      }
    },
    canActivateUser: async (id, hash) => {
      const res = await Service.canActivateUser(id, hash)
      const { message, email } = await res.json()
      if (res.ok) {
        setActivationIsPossible(true)
        setFormUsername(email)
      } else {
        failedActivation(id, message)
        setActivationIsPossible(false)
      }
    },
    activateUser: async (id, hash, password) => {
      setActivationIsFetching(true)
      const res = await Service.activateUser(id, hash, password)
      const { data, message } = await res.json()
      setActivationIsFetching(false)
      if (res.ok) {
        successfulLogin(data)
      } else if (res.status === 401) {
        failedLogin(message, setActivationMessage)
      } else {
        failedActivation(id, message)
      }
    },
    requestResetPassword: async (email, url) => {
      const res = await Service.requestResetPassword(email, url)
      const { message } = await res.json()
      if (res.ok) {
        setResetPasswordRequestIsSuccess(true)
        setResetPasswordMessage('')
      } else {
        if (message === 'account-not-found') {
          setResetPasswordMessage(
            <>
              <p>This email is not yet registered.</p>
              <p>
                Would you like to {" "}
                <span
                  className="has-text-info has-pointer"
                  onClick={()=>{
                    setResetPasswordMessage('')
                    popSignUp()
                  }}
                >sign-up</span>?
              </p>
            </>
          )
        } else if (message === 'too-many-requests') {
          setResetPasswordMessage(
            <>
              <p>Several emails have been sent here already.</p>
              <p>If you cannot find them, please check your spam folder.</p>
            </>
          )
        } else {
          setResetPasswordMessage(
            <>
              <p>Something went wrong.</p>
              <p>Please try again later.</p>
            </>
          )
        }
      }
    },
    canResetPassword: async (id, hash) => {
      const res = await Service.canResetPassword(id, hash)
      const { message, email } = await res.json()
      if (res.ok) {
        setResetPasswordIsPossible(true)
        setFormUsername(email)
      } else {
        failedResetPassword(message)
        setResetPasswordIsPossible(false)
      }
    },
    resetPassword: async (id, hash, password) => {
      setResetPasswordIsFetching(true)
      const res = await Service.resetPassword(id, hash, password)
      const { data, message } = await res.json()
      setResetPasswordIsFetching(false)
      if (res.ok) {
        successfulLogin(data)
      } else if (res.status === 401) {
        failedLogin(message, setResetPasswordMessage)
      } else {
        failedResetPassword(message)
      }
    },
  }

  //Local vars
  const TOKEN_TYPE = 'Bearer'
  useEffect(()=>{
    const cookie_token = Cookies.get(COOKIE_NAME)
    if (cookie_token && !token) {
      setToken(cookie_token)
    } else if (token && !me.id) {
      authMethods.getProfile()
    }
  },[token, me])

  const auth = {
    token,
    me,
    loginMessage,
    signUpMessage,
    resetPasswordMessage,
    activationMessage,
    formUsername,
  }

  return (
    <AuthContext.Provider
      value={{auth, authMethods, authStatus}}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
