import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Service from '../../service'
import Logo from '../logo'
import './index.scss'

const { CDN } = Service

const CallToAction = () => {
  return (
    <div className="page landing call-to-action">
      <div className="columns is-fullwidth has-text-centered animate__animated animate__fadeInDown">
        <div className="column is-2 is-offset-1">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="column is-1 is-offset-5 has-text-right animate__animated animate__fadeIn">
          <div className="icon-container">
            <a href="https://open.spotify.com/album/3AYk6SCflnY1mMvb7hSddm?si=O5gGs6n9S8qAvhjo-d_yWg" target="_blank">
              <img
                className="sm-icon"
                src={`${CDN}spotify_3zICcYQvz.png`}
                alt="Listen to Bill Godfrey on Spotify"
              />
            </a>
            <a href="https://music.apple.com/us/album/hypnotized-ep/1705667106" target="_blank">
              <img
                className="sm-icon"
                src={`${CDN}apple_music_white_gl-1oBckbX3.png`}
                alt="Listen to Bill Godfrey on Apple Music"
              />
            </a>
            <a href="https://www.youtube.com/watch?v=tZpHjLNrB8E&list=OLAK5uy_n5JgkD-KYuWCzOy0rURgYAdzasMPAr4G0" target="_blank">
              <img
                className="sm-icon"
                src={`${CDN}youtube_S6DDUv58Nxc.png`}
                alt="Listen to Bill Godfrey on YouTube"
              />
            </a>
            <a href="https://music.amazon.com/albums/B0CH6Y4BC8" target="_blank">
              <img
                className="sm-icon"
                src={`${CDN}am_music_DOEgYRqz2Ud.png`}
                alt="Listen to Bill Godfrey on Pandora"
              />
            </a>
          </div>
        </div>
        <div className="column is-2" style={{display: 'flex'}}>
          <a href="https://hypnotized.billgodfrey.com" target="_blank">
            <img
              className="album-cover" 
              src={`${CDN}hypnotized-album-cover_rx4wbyVMx.png`}
              alt="Album cover of the debut album Hypnotized"
            />
            <p className="watch-video">Watch the video!</p>
          </a>
        </div>
      </div>
      <div className="navigation animate__animated animate__fadeInUp">
        <Link to="/blog">
          Blog
          <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
        </Link>
	<br />
        <a href="https://linktr.ee/BillGodfrey" target="_blank">
          Linktree
          <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
        </a>
      </div>
      <div className="socials animate__animated animate__fadeInUp">
        <a href="https://www.facebook.com/billgodfreymusic/" target="_blank">
          <FontAwesomeIcon icon={faFacebook} size='3x' />
        </a>
        <a href="https://www.instagram.com/billgodfreymusic/" target="_blank">
          <FontAwesomeIcon icon={faInstagram} size='3x' />
        </a>
        <a href="https://www.youtube.com/playlist?list=OLAK5uy_n5JgkD-KYuWCzOy0rURgYAdzasMPAr4G0" target="_blank">
          <FontAwesomeIcon icon={faYoutube} size='3x' />
        </a>
      </div>
    </div>
  )
}

export default CallToAction
