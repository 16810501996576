import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { ModalContext } from '../../contexts/modal'
import { AuthContext } from '../../contexts/auth'
import './index.scss' 

const Modal = () => {
  const { resetAuthModals } = useContext(AuthContext).authMethods
  const { modal, modalMethods } = useContext(ModalContext)
  const { isActive, content, width, xIsHidden } = modal
  const { close } = modalMethods
  const xHidden = xIsHidden ? 'is-hidden' : ''
  return (
    <div className={`basic-modal modal ${(isActive)?'is-active':''}`}>
      <div className="modal-background" />
      <div className="modal-card animated fadeIn" id="modal-card" style={{width}}>
        <div className="x-icon-container">
          <FontAwesomeIcon
            icon={faTimesCircle}
            className={`x-icon ${xHidden}`}
            onClick={()=>{
              resetAuthModals()
              close()
            }}
          />
        </div>
        <section className="modal-card-body">
          {content}
        </section>
      </div>
    </div>
  )
}

export default Modal
