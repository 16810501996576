import React, { useContext } from 'react'
import Service from '../../service'
import './index.scss'

const { CDN } = Service

const Logo = () => {
  const src = 'bg_logo_yellow_cRghA0eg2.png'
  return (
    <img
      src={`${CDN}${src}`}
      className="logo animate__animated animate__fadeInDown"
      alt="Bill Godfrey"
    />
  )
}

export default Logo
