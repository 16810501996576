import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import './index.scss'

const Nav = () => {
  return (
    <div className="nav animate__animated animate__fadeInDown animate__slow">
      <Link className="nav-link" to="/blog">Blog</Link>
      <span>&bull;</span>
      <Link className="nav-link" to="/epk">EPK</Link>
    </div>
  )
}

export default Nav
