import React, { useContext, useEffect } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { BlogContext } from '../../contexts/blog'
import Service from '../../service'
import './index.scss'

const _listBlogPosts = posts => posts.map((post, i) => {
  const published_at = moment(post.published_at).format('M/D/YYYY')
  return (
    <div className="preview-post-container" key={`${post.title}-${i}`}>
      <h1>
        <Link to={`/blog/${post.route}`}>{published_at} {post.title}</Link>
      </h1>
      <p>
        <Link to={`/blog/${post.route}`}>
          <img src={`${Service.CDN}${post.image}`} alt={post.alt} className="blog-image" />
        </Link>
        <span dangerouslySetInnerHTML={{ __html: post.body}} />
      </p>
    </div>
  )
})

const BlogList = () => {
  const { blog, blogStatus, blogMethods } = useContext(BlogContext)
  const { blogList } = blog
  const { isRequesting } = blogStatus
  if (isRequesting) return 'Loading...'

  useEffect(()=>{
    if (!Array.isArray(blogList)) {
      blogMethods.getAllBlogs()
    }
  },[blogList])

  const list = (Array.isArray(blogList) && blogList.length) ? _listBlogPosts(blogList) : null

  return (
    <div className="page blog animate__animated animate__fadeIn animate__slow" >
      <div className="go-back">
        <Link to="/">
          <FontAwesomeIcon icon={faArrowLeft} className="mr-4" /> 
          Back
        </Link>
      </div>
      {list}
    </div>
  )
}

export default BlogList
