import React, { useContext } from 'react'
import Service from '../../service'

const { CDN } = Service

const ElectronicPressKit = () => {
  return (
    <div className="page electronic-press-kit animate__animated animate__fadeIn">
      <img src={`${CDN}terlingua.jpeg`} style={{height: '50vh'}} />
    </div>
  )
}

export default ElectronicPressKit
