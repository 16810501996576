import React, { createContext, useState, useEffect, useRef, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from './auth'
import { SocketContext } from './socket'
import Service from '../service'

export const BlogContext = createContext()

const DEFAULT_BLOG_POST = {
  title: null,
  body: null,
  image: null,
  route: null,
  published_at: null,
}

const DEFAULT = {
  isRequestingBlog: false,
  isErrorBlog: false,
  isRequestingComments: false,
  isErrorComments: false,
  updatingFor: null,
  blogList: null,
  blogPost: DEFAULT_BLOG_POST,
  comments: null,
  commentsNeedReload: false,
  commentingOn: null,
}

const BlogProvider = props => {
  const { token, me } = useContext(AuthContext).auth
  const { socket } = useContext(SocketContext)
  const [ blogList, setBlogList ] = useState(DEFAULT.blogList)
  const [ blogPost, setBlogPost ] = useState(DEFAULT.blogPost)
  const [ comments, setComments ] = useState(DEFAULT.comments)
  const [ commentsNeedReload, setCommentsNeedReload ] = useState(DEFAULT.commentsNeedReload)
  const [ isRequestingBlog, setIsRequestingBlog ] = useState(DEFAULT.isRequestingBlog)
  const [ isErrorBlog, setIsErrorBlog ] = useState(DEFAULT.isError)
  const [ isRequestingComments, setIsRequestingComments ] = useState(DEFAULT.isRequestingComments)
  const [ isErrorComments, setIsErrorComments ] = useState(DEFAULT.isError)
  const [ replyTo, setReplyTo ] = useState(DEFAULT.commentingOn)
  const [ updatingFor, setUpdatingFor ] = useState(DEFAULT.updatingFor)

  const blogMethods = {
    getAllBlogs: async ()=> {
      setIsRequestingBlog(true)
      const res = await Service.getAllBlogs()
      if (res.ok) {
        const data = await res.json()
        setBlogList(data.blog)
        setIsRequestingBlog(false)
      } else {
        setIsErrorBlog(true)
        setIsRequestingBlog(false)
      }
    },
    getOneBlog: async route => {
      setIsRequestingBlog(true)
      setCommentsNeedReload(true)
      const res = await Service.getOneBlog(route)
      if (res.ok) {
        const data = await res.json()
        setBlogPost(data.blog)
        setIsRequestingBlog(false)
      } else {
        setIsRequestingBlog(false)
        setIsErrorBlog(true)
      }
    },
    getComments: async (blogid, userId=null) => {
      setIsRequestingComments(true)
      const res = await Service.getComments(blogid, userId)
      setCommentsNeedReload(false)
      if (res.ok) {
        const { tree } = await res.json()
        setComments(tree)
        setIsRequestingComments(false)
      } else {
        setComments([])
        setIsErrorComments(true)
        setIsRequestingComments(false)
      }
    },
    reloadComments: () => {
      setCommentsNeedReload(true)
    },
    createComment: async (userId, blogId, body) => {
      setIsRequestingComments(true)
      const res = await Service.createComment(
        userId,
        blogId,
        body,
        replyTo,
        token,
      )
      if (res.ok) {
        const { tree } = await res.json()
        setIsRequestingComments(false)
        setComments(tree)
        socket.emit('update-comments',{
          blogId,
          userId,
        })
      } else {
        setIsErrorComments(true)
        setIsRequestingComments(false)
      }
    },
    updateComment: async (commentId, blogId, userId, body) => {
      const res = await Service.updateComment(commentId, blogId, userId, body, token)
      if (res.ok) {
        const { tree } = await res.json()
        setIsRequestingComments(false)
        setComments(tree)
        socket.emit('update-comments', {
          blogId,
          userId,
        })
      } else {
        setIsErrorComments(true)
        setIsRequestingComments(false)
      }
    },
    deleteComment: async (commentId, userId, blogId) => {
      const res = await Service.deleteComment(commentId, userId, blogId, token)
      if (res.ok) {
        const { tree } = await res.json()
        setIsRequestingComments(false)
        setComments(tree)
        socket.emit('update-comments', {
          userId,
          blogId,
        })
      } else {
        setIsErrorComments(true)
        setIsRequestingComments(false)
      }
    },
    setUpdatingFor,
    setReplyTo,
    upvote: async (userId, commentId, blogId, remove=false) => {
      const res = await Service.upvote(userId, commentId, blogId, remove, token)
      if (res.ok) {
        const { tree } = await res.json()
        setComments(tree)
        socket.emit('update-comments', {
          userId,
          blogId,
        })
      }
    },
    downvote: async (userId, commentId, blogId, remove=false) => {
      const res = await Service.downvote(userId, commentId, blogId, remove, token)
      if (res.ok) {
        const { tree } = await res.json()
        setComments(tree)
        socket.emit('update-comments', {
          userId,
          blogId,
        })
      }
    },
  }

  const blogStatus = {
    isRequestingBlog,
    isErrorBlog,
    isRequestingComments,
    isErrorComments
  }

  const blog = {
    blogList,
    blogPost,
    comments,
    replyTo,
    commentsNeedReload,
    updatingFor,
  }

  return (
    <BlogContext.Provider
      value={{
        blog,
        blogMethods,
        blogStatus
      }}
    >
      { props.children }
    </BlogContext.Provider>
  )
}

export default BlogProvider
